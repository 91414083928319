import React from "react"

import SEO from "../shared_components/seo"

import "@fortawesome/fontawesome-free/css/all.css"

import Card from '../shared_components/gatsby-card'
import Email from '../shared_components/emailobfuscator'
import Header from '../shared_components/gatsby-header'

class Content extends React.Component {
  render() {
    var size = this.props.size;
    var bodyStyle = Object.assign({
      flex: '1 100%',
      padding: '' + (3 * size) + 'px',
      fontSize: '' + (10 * size) + 'px',
      background: 'white',
      color: 'black',
      fontFamily: 'Arial, san-serif',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }, this.props.style);
    return (
      <div style={bodyStyle}>{this.props.children}</div>
    )
  }
}

export default () => (
  <div style={{boxShadow: '0 0 4px gray'}}>
    <SEO title="Bitframe LLC" />
    <Card image='/BitframeLogoConcept.png' imageStyle={{ height: '150px', width: '150px'}}>
      <Header size='6' style={{fontWeight: 'bold', textTransform: 'capitalize', textAlign: 'center'}}>
        Bitframe LLC
      </Header>
      <Header size='3' style={{fontWeight: 'lighter', fontStyle: 'italic', textAlign: 'center'}}>
        Software at Scale
      </Header>
    </Card>
    <Content>
      <img src='/brainstorming.jpg' alt="Brainstorming" style={{width: '100%', maxWidth: '1000px'}} />

      <Header size='4' style={{fontWeight: 'bold'}}>
        About Us
      </Header>
      <div style={{margin: '10px', textAlign: 'left'}}>
        <p>
          Bitframe LLC builds out large-scale software infrastructure with as much automation as possible so you can focus on your business.
          We work hard to ensure users have the best experience without unnecessary roadblocks.
        </p><p>
          We have a track record of delivering high impact:
        </p>
        <ul className="impact-list">
          <li>
            <i class="fas fa-laptop-code" style={{margin: '0 4px'}} />
            <b>50+ releases</b> managed for well known open-source projects
          </li>
          <li>
            <i class="fas fa-vial" style={{margin: '0 8px'}} />
            Over <b>15 million tests run</b> in 2020, in addition to other automation services
          </li>
          <li>
            <i class="fas fa-shield-alt" style={{margin: '0 7px'}} />
            Security management for a high-uptime network valued over <b>$4 billion</b>
          </li>
        </ul>
      </div>

      <Header size='4' style={{fontWeight: 'bold'}}>
        Services We Provide
      </Header>
      <ul className="service-list">
        <li>
          <b>Infrastructure Evaluation & Development</b>
          <ul>
            <li>Automation - make your code work for you</li>
            <li>Scaling - build & design for 1000x bigger than you are today</li>
            <li>Organizational Evaluation - make people and machines work better together</li>
            <li>Training - learn the best methods for building and maintaining your codebase</li>
          </ul>
        </li>
        <li>
          <b>Technical Information Resources - Make the biggest impact on your decision-making</b>
          <ul>
            <li>Statistics gathering</li>
            <li>Fact checking</li>
          </ul>
        </li>
        <li>
          <b>Security - Catch issues before they happen</b>
          <ul>
            <li>High-level security planning</li>
            <li>Code auditing</li>
          </ul>
        </li>
        <li>
          <b>Lawsuit & Litigation Support - Get informed from a subject matter expert</b>
          <ul>
            <li>Expert Witness</li>
            <li>Consulting (non-testifying) - Just need some guidance and understanding? Our non-witness services
            come a more affordable price</li>
          </ul>
        </li>
      </ul>

      <div style={{padding: '20px'}}>
        <Email name='info' domain='bitframe' tld='org' size='2'></Email>
      </div>
    </Content>
  </div>
)
