import React from "react"

export default ({ size, style, children }) => {
  const headerStyle = Object.assign({
    flex: '1 100%',
    padding: '' + (3 * size) + 'px',
    fontSize: '' + (10 * size) + 'px',
  }, style)

  return (
    <div style={headerStyle}>{children}</div>
  )
}
